import logo from './logo.svg';
import './App.css';
import Sightings from './components/giant-squid-tracker/Sightings';

const render = (status) => {

  return <h1>{status}</h1>;
};

function App() {
  return (
<div className="App">
      <Sightings />
    </div>
  );
}

export default App;
