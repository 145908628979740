import React from "react";
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';

const GOOGLE_MAPS_API_KEY = "AIzaSyDn1Sy40vo17h_I-mrsWEpO_ysRJt0Wsi4";
// FontAwesomeIcon.library.add(faCheckSquare, faCoffee);


// const Marker = ({ text }) => <div>{text}</div>;
const Marker = ({text}) => <FontAwesomeIcon icon={faLocationPin}/>;

const Locations = [
    {
          lng: -77.866354,
          lat: 26.462378,
          text: "Giant Squid Sighting 1/14/23"
    },
    {
          lng: -78.958568,
          lat: 29.226897,
          text: "Giant Squid Sighting 2/1/23"
    },
    {
        lng: -95.127189,
        lat: 29.025690,
        text: "Giant Squid Sighting 3/1/23"
    },
    {
        lng: -80.033102,
        lat: 26.947189,
        text: "Giant Squid Sighting 3/10/23"
    },
    {
        lng: -81.448571,
        lat: 30.513010,
        text: "Giant Squid Sighting 3/11/23"
    },
    // {
        // lng: -97.716949,
        // lat: 30.247788,
        // text: "Giant Squid Sighting 3/13/23"
    // },
]; 

export default function Map(){
  const defaultProps = {
    center: {
      lng: -77.866354,
      lat: 26.462378
    },
    zoom: 5
  };

  return (
    // Important! Always set the container height explicitly
    <>

    <div style={{ height: '100vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {Locations.map(l => (
            <Marker
            lng={l.lng}
            lat={l.lat}
            text={l.text}
            />
        ))}
        <Marker
          lng={-77.866354}
          lat={26.462378}
          text="Giant Squid Sighting"
        />
        <Marker
          lng={-78.958568}
          lat={29.226897}
          text="Giant Squid Sighting"
        />
      </GoogleMapReact>
    </div>
    </>
  );
}