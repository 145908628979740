import React from "react";
import Map from "./Map";


const Sightings = () => (
  <>
    <div id="sightings">
      <h1>Giant Squid Sightings</h1>
      <p className="mission-statement">
        As we all know, the greatest threat to our democracy is already amongst us, moving unchecked and unrestricted.
        Living in secrecy. Thriving in the deepest, darkest parts of our world. It is up to us to stop it. Us and only us. The world as we know it is oblivious to this threat. The government tuns a blind eye, the mainstream media mocks, and the general public believes that ignorance is bliss. Well, we do not. We will fight until our dying breaths to save this nation, nay, this planet, from that which is most evil. That is why we have dedicated this website to the tracking of Giant Squids.
      </p>

      <p className="mission-statement">
        Our mission statement is to give real time updates on all known giant squid activity. This may seem like a baby step in the grand scheme of things, and you would be right. But Rome was not built in a day. We will strive to keep you safe and keep this world safe, one giant squid tracker at a time.
      </p>
      <Map />
    </div>
  </>
);

export default Sightings;
